import { useEffect, useState } from 'react'
import { ExternalRequest } from 'appsby'

const merge = require('deepmerge')

/*

	This hook fetches Gatsby page-data.json files in the background and merges them
	with existing page data to create infinite scrolling.

 */

export function useInfiniteScroll({ initialData, initialPageContext }) {
  const [pageData, setPageData] = useState(initialData);
  // eslint-disable-next-line no-unused-vars
  const [pageContext, setPageContext] = useState(initialPageContext);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [before, setBefore] = useState(initialPageContext.previousPagePath);
  const [after, setAfter] = useState(initialPageContext.nextPagePath);
  // eslint-disable-next-line no-unused-vars
  const [beforeId, setBeforeId] = useState(initialPageContext.previousPagePath);
  const [afterId, setAfterId] = useState(initialPageContext.nextPagePath);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    if (isBottom && after) {
      setAfterId(after);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  useEffect(() => {
    if (!afterId) return;


    ExternalRequest('GET', undefined, `/page-data${afterId}/page-data.json`, true, 15, false).then((result) => {
      if (!result.success) {
        setError(result.result);
        return;
      }

      setHasMore(false);
      setPageContext(result.result.result.pageContext);

      //setPageData(pageData.concat([result.result.result.data]));
      setPageData(merge(pageData, result.result.result.data))

      if (result.result.result.pageContext.previousPagePath) {
        setBefore(result.result.result.pageContext.previousPagePath);
      }
      if (result.result.result.pageContext.nextPagePath) {
        setAfter(result.result.result.pageContext.nextPagePath);
        setHasMore(true);
      }
      setIsLoading(false);
      setIsBottom(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beforeId, afterId]);

  return { isLoading, hasMore, error, pageData, before, after, setIsBottom };
}
