import React from 'react'
import { Link } from 'gatsby'
import { toExcerpt } from '../lib/helpers'

interface Props {
  _rawTitle: string
  _rawBody: string
  _rawSlug: any
  _rawTags: any
  image: any
  language: number
}

export const BlogCard = ({ _rawTitle, _rawBody, _rawSlug, _rawTags, image, language }: Props) => {
  return (
    <div className="col-md-4 mb-4">
      <div className="card-blog">
        <Link to={_rawSlug[language].current} className="img-holder" style={{ backgroundImage: `url(${image?.asset?.localFile?.publicURL})` }} />
        <div className="description">
          <h3 className="title">
            <Link to={_rawSlug[language].current}>{_rawTitle[language]}</Link>
          </h3>
          <div className="text-content">{toExcerpt(_rawBody[language], 40)}</div>
        </div>
      </div>
    </div>
  )
}
