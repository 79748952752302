import React from 'react'
import { graphql } from 'gatsby'
import { BlogCard } from '../components/blog-card'
import GraphQLErrorList from '../components/graphql-error-list'
import { Seo } from '../components/seo'
import PortableText from '../lib/portableText'
import { useInfiniteScroll } from '../hooks/useInfiniteScroll'

export const query = graphql`
  query BlogListingTemplateQuery($skip: Int!, $limit: Int!) {
    allSanitySiteSettings {
      nodes {
        blogTitle {
          en
          et
          fi
        }
        _rawBlogDescription
      }
    }
    allSanityPost(skip: $skip, limit: $limit) {
      nodes {
        id
        _rawBody
        _rawSlug
        _rawTitle
        _rawTags
        image {
          asset {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

const BlogListingTemplate = (props) => {
  const { data, errors, location } = props

  let lang = props.pageContext.language
  let title = props.pageContext.title
  let text

  if (data.allSanitySiteSettings.nodes && data.allSanitySiteSettings.nodes[0]._rawBlogDescription) {
    text = data.allSanitySiteSettings.nodes[0]._rawBlogDescription[lang]
  }

  const { pageData } = useInfiniteScroll({ initialData: data, initialPageContext: props.pageContext })
  const metas = [
    {
      property: 'og:type',
      content: 'article',
    },
  ]
  return (
    <>
      {errors && <GraphQLErrorList errors={errors} />}
      <>
        <Seo title={title} language={lang} location={location} metas={metas} />
        <main className="main">
          <div className="hero-blog">
            <div className="container px-4">
              <div className="row">
                <div className="col-md-10">
                  {title && <h1 className="title">{title}</h1>}
                  {text && (
                    <div className="text">
                      <PortableText blocks={text} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="holder-cards">
            <div className="container">
              <div className="row">
                {pageData && pageData.allSanityPost.nodes.map((node) => <BlogCard key={node.id} {...node} language={lang} />)}
              </div>

            </div>
          </div>
        </main>
      </>
    </>
  )
}

export default BlogListingTemplate
